import React, { useEffect, useState } from 'react';
import Container from '../Container/Container';
import logo from '../../assets/images/icons.svg';
import {
  StyledBurger,
  StyledButtonClose,
  StyledHeader,
  StyledInner,
  StyledLogo,
  StyledMobileLogo,
  StyledMobileMenu,
  StyledMobileMenuHeader,
  StyledMobileNavLink,
  StyledMobileNavList,
  StyledNavLink,
  StyledNavList,
  StyledWrapperBurgerLogo,
} from './Header.styled';
import SvgSprite from '../../assets/images/icons.svg';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const middleOfScreen = window.innerHeight * 0.55;
      let currentActiveSection = null;

      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= middleOfScreen && rect.bottom >= middleOfScreen) {
          currentActiveSection = section.id;
        }
      });
      setActiveSection(currentActiveSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -79;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    if (window.innerWidth <= 1439 && isOpen) {
      setIsOpen(!isOpen);
    }
  };

  const padding = '0';

  return (
    <StyledHeader>
      <Container $padding={padding}>
        <StyledInner>
          <StyledWrapperBurgerLogo>
            <StyledBurger
              onClick={() => setIsOpen(!isOpen)}
              aria-label="Navigation menu on the homepage"
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <use href={SvgSprite + '#burger'} />
              </svg>
            </StyledBurger>
            <StyledLogo
              to="/"
              aria-label="Go to navigation menu on the homepage"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            >
              <svg style={{ width: '48px', height: '48px' }}>
                <use xlinkHref={`${logo}#logo`} />
              </svg>
              Оксана Опенько
            </StyledLogo>
          </StyledWrapperBurgerLogo>

          <StyledNavList>
            <StyledNavLink
              to="/#about-me"
              scroll={el => scrollWithOffset(el)}
              aria-label="Go to section About Me on the homepage"
              className={activeSection === 'about-me' ? 'active' : ''}
            >
              Про мене
            </StyledNavLink>
            <StyledNavLink
              to="/#services"
              scroll={el => scrollWithOffset(el)}
              aria-label="Go to section Services Me on the homepage"
              className={activeSection === 'services' ? 'active' : ''}
            >
              Послуги
            </StyledNavLink>
            <StyledNavLink
              to="/#rules"
              scroll={el => scrollWithOffset(el)}
              aria-label="Go to section Rules of Work on the homepage"
              className={activeSection === 'rules' ? 'active' : ''}
            >
              Правила роботи
            </StyledNavLink>
            <StyledNavLink
              to="/#contacts"
              scroll={el => scrollWithOffset(el)}
              aria-label="Go to section Contacts on the homepage"
              className={activeSection === 'contacts' ? 'active' : ''}
            >
              Контакти
            </StyledNavLink>
          </StyledNavList>

          <StyledMobileMenu open={isOpen}>
            <StyledButtonClose
              onClick={() => setIsOpen(!isOpen)}
              aria-label="Close navigation menu"
            >
              <svg width="40" height="40" viewBox="0 0 40 40">
                <use href={SvgSprite + '#btn-close'} />
              </svg>
            </StyledButtonClose>

            <StyledMobileMenuHeader>
              <StyledMobileLogo
                to="/"
                aria-label="Go to navigation menu on the homepage"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              >
                <svg style={{ width: '48px', height: '48px' }}>
                  <use xlinkHref={`${logo}#logo`} />
                </svg>
                Оксана Опенько
              </StyledMobileLogo>
            </StyledMobileMenuHeader>

            <StyledMobileNavList>
              <StyledMobileNavLink
                to="/#about-me"
                scroll={el => scrollWithOffset(el)}
                aria-label="Go to section About Me on the homepage"
              >
                Про мене
              </StyledMobileNavLink>
              <StyledMobileNavLink
                to="/#services"
                scroll={el => scrollWithOffset(el)}
                aria-label="Go to section Services Me on the homepage"
              >
                Послуги
              </StyledMobileNavLink>
              <StyledMobileNavLink
                to="/#rules"
                scroll={el => scrollWithOffset(el)}
                aria-label="Go to section Rules of Work on the homepage"
              >
                Правила роботи
              </StyledMobileNavLink>
              <StyledMobileNavLink
                to="/#contacts"
                scroll={el => scrollWithOffset(el)}
                aria-label="Go to section Contacts on the homepage"
              >
                Контакти
              </StyledMobileNavLink>
            </StyledMobileNavList>
          </StyledMobileMenu>
        </StyledInner>
      </Container>
    </StyledHeader>
  );
}
